body{
    background-color: #F9F9F9;
    max-width: 100%;
    max-height: 100% !important;
}

.conta{
    display: flex;
    width: 100%;
    height: 100%;
}

.top-page{
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.img-form{
    width: 38px;
    height: 38px;
}

.form_log{
    align-self: center;
    justify-content: center;
    text-align: center;
}

.form_input{
    width: 60%;
}

.top-page h1{
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.top-page h2{
    font-size: 50px;
    font-weight: bold;
}

.bt{
    align-items: center;
}

.foot{
    margin-top: 80%;
}

.foot pre{
    right: 100%;
}