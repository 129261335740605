@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input, button, textarea {
  font: 400 18px Roboto, sans-serif;
}

button {
  cursor: pointer;
}

form input {
  width: 100%;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 0 24px;
  margin-bottom: 20px;
}

form textarea {
  width: 100%;
  resize: vertical;
  min-height: 140px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 16px 24px;
  line-height: 24px;
}

.button {
    margin-left:auto;
    margin-right: auto;
  width: 30%;
  height: 60px;
  background: #3498db;
  border: 0;
  border-radius: 8px;
  color: #FFF;
  font-weight: 700;
  margin-top: 16px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
}

.button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.back-link {
  display: flex;
  align-items: center;
  margin-top: 40px;
  color: #41414d;
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.2s;
}

.back-link svg {
  margin-right: 8px;
}

.back-link:hover {
  opacity: 0.8;
}
body{
  background-color: #F9F9F9;
}

.marginTop{
  margin-top: 6rem;

}
.jBorder{
display: flex;
margin-top: -200px !important;
padding: 0 0 0 0 !important;
margin: 0 0 0 0 !important;
-webkit-filter: drop-shadow(15px 10px 5px #888888);
        filter: drop-shadow(15px 10px 5px #888888);

}


.imageBack{
  background-image: url(/static/media/undrawBack.18464b5c.svg) !important;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-attachment: initial;
  background-position: center;
  background-size: cover;
  background-size: 100% 100%;
  font-weight: 900 !important;
  font-size: large;
}

.imgBack{
  -webkit-filter: contrast(85%) !important;
          filter: contrast(85%) !important;
}

strong{
  color: #F6AE2D;
}


body{
    background-color: #F9F9F9;
    max-width: 100%;
    max-height: 100% !important;
}

.conta{
    display: flex;
    width: 100%;
    height: 100%;
}

.top-page{
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.img-form{
    width: 38px;
    height: 38px;
}

.form_log{
    align-self: center;
    justify-content: center;
    text-align: center;
}

.form_input{
    width: 60%;
}

.top-page h1{
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.top-page h2{
    font-size: 50px;
    font-weight: bold;
}

.bt{
    align-items: center;
}

.foot{
    margin-top: 80%;
}

.foot pre{
    right: 100%;
}
.container{
    padding: 0;
}

html, 
body {
    height: 100%;
}

.il{
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
    margin-left: 0px !important;
    display: inline !important;
}

.title{
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.ir{
    float: right;
    margin-top: 10px !important;
    font-size: 20px;
}

.Img{
    width: 60px;
    height: 60px;
    margin-bottom: 15%;
    object-fit: cover;
}

.mid_Content{
    height: 100%!important;
    max-height: 100% !important;
}

.ImgSide{
    width: 40px;
    height: 40px;
    left: 97.5%;
    top: 50%;
    z-index: 1;
    -webkit-filter: drop-shadow(15px 10px 5px #888888);
            filter: drop-shadow(15px 10px 5px #888888);
    border-style: solid;
    padding: 3px;
    border-radius: 10px;
    border-color: #f1f1f1;
    background-color: #f1f1f1;;
    position: absolute;
}

.img_bot{
    width: 45px;
    height: 45px;
    margin: 10px 20px 10px 20px;
}

.img_bot_right{
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    right: 0%;
    bottom: 1rem !important;
    
}

.cars_cont{
    text-align: center;
    align-content: center;
}

.card_title{
    font-size: 25px;
}

.card{
    padding: 2 !important;
    -webkit-filter: drop-shadow(15px 10px 5px #888888);
            filter: drop-shadow(15px 10px 5px #888888);
}

.ct{
    max-height: 100% !important;
    height: 100%;
}

.nave{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
}

.footer {
    position: fixed !important;
    margin-right: 14rem!important;
    margin-left: 4.5rem!important;
    width: 100%!important;
    bottom: 1% !important;

  }
  
.filter{
    opacity: 0.4;
}

.no_filter{
    -webkit-filter: grayscale(0%) !important;
            filter: grayscale(0%) !important;
}

.disabledCursor{
    cursor:default;
}

p{
    font-weight: 900 !important;
}

.logo{
    width: 120px;
    height: 120px;
}
/* The side navigation menu */
.sidenav {
    border-right: 5px solid;
    border-color: #fff;
    height: 100%; /* 100% Full-height */
    width: 14rem; /* 0 width - change this with JavaScript */
    display: inline-block;
    position: fixed;
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #F6AE2D; 
    bottom: 0 !important;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }

  .sidenav h5{
      font-size: 24px;
  }
  
.sidenav img{
    border: 2px solid;
    border-color: #f0f0f5;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 50px;
}


  /* The navigation menu links */
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 14px;
    font-weight:  500;
    color: #000;
    display: block;
    transition: 0.3s;
  }

  .collapse a{
    margin-left: 20px;
  }


  
  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  /* Position and style the close button (top right corner) */
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  /* Style page content - use this if you want to push the page content to the right when you open the side navigation */
  #main {
    transition: margin-left .5s;
    padding: 20px;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }




@media (max-width: 579px){
    .sidenav {
        height: 0px; /* 100% Full-height */
        width: 0px; /* 0 width - change this with JavaScript */
        display: inline-block;
        position: fixed;
        z-index: 1; /* Stay on top */
        top: 0; /* Stay at the top */
        left: 0;
        background-color: #F6AE2D; 
        bottom: 0 !important;
        overflow-x: hidden; /* Disable horizontal scroll */
        padding-top: 60px; /* Place content 60px from the top */
        transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
      }

    .img_bot{
        width: 35px !important;
        height: 35px !important;
        margin: 5px 10px 5px 10px !important;
    }

    .nave{
        
        justify-content: center!important;
        margin-left: 35px !important;
        margin-right: 0px !important;
        margin-top: 30px !important;
        

    }

    }
.il{
    display: inline;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.html,body{
    height: 100%;
    max-width: 100%;
    max-height: 100%!important;
    width: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}
.esteiraAtivo{
    border-style: solid;
    border-width: 2px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
    border-radius: 10px !important;
}

.esteiraAtivo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.esteiraDesativo{
    border-style: solid;
    border-width: 2px;
    box-shadow: 5px 5px #888888;
    background: #797877;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
    border-radius: 10px !important;
}
.esteiraDesativo p{
    margin-top: 1rem;
    margin-left: 3rem;
}


.itemAtivo{
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    box-shadow: 5px 5px #888888;
    background-color: #F6AE2D;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.itemDesativo{
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    box-shadow: 5px 5px #888888;
    background: #BAB8B5!important;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.itemAtivo .card-header {
    background-color: #F6AE2D!important;
}

.itemDesativo .card-header{
    background-color: #BAB8B5!important;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 30px !important;
}

.title{
    margin-left: 2rem;
    margin-top: -1rem;
    margin-bottom: -1.4rem;
}

.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F6AE2D !important;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}


.mg-top{
    margin-top: 5%;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 30px !important;
}

.title{
    margin-left: 2rem;
    margin-top: -1rem;
    margin-bottom: -1.4rem;
}

.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F6AE2D !important;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}


.mg-top{
    margin-top: 5%;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 30px !important;
}

.title{
    margin-left: 2rem;
    margin-top: -1rem;
    margin-bottom: -1.4rem;
}

.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F6AE2D !important;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}


.mg-top{
    margin-top: 5%;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 30px !important;
}

.title{
    margin-left: 2rem;
    margin-top: -1rem;
    margin-bottom: -1.4rem;
}

.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 35px !important;
    height: 35px !important;
}


.mg-top{
    margin-top: 5%;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.card-header{
    background-color: #F6AE2D !important;
    border-radius: 30px !important;

}

.central{
    margin-top: 20rem;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 30px !important;
}

.title{
    margin-left: 2rem;
    margin-top: -1rem;
    margin-bottom: -1.4rem;
}

.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

button{
    border-width: none !important;
    border-style: none !important;

}
.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}


.mg-top{
    margin-top: 5%;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.central{
    margin-top: 20rem;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 30px !important;
}

.title{
    margin-left: 2rem;
    margin-top: -1rem;
    margin-bottom: -1.4rem;
}

.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

button{
    border-width: none !important;
    border-style: none !important;

}
.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}


.mg-top{
    margin-top: 5%;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.card-header{
    border-radius: 10px !important;
}

.central{
    margin-top: 20rem;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 10px !important;
}

.title{
    margin-left: 2rem;
    margin-top: -1rem;
    margin-bottom: -1.4rem;
}

.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

button{
    border-width: none !important;
    border-style: none !important;
    background: none ;
}
.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}

.img_mini{
    width: 25px;
    height: 25px;
    cursor: pointer;
}


.mg-top{
    margin-top: 5%;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.checks{
    width: 30px;
    height: 30px;
    cursor: pointer;
}



.confirmarModal{
    border-right: 5px solid;
    border-color: #fff;
    height: 130px; /* 100% Full-height */
    width: 0px; /* 0 width - change this with JavaScript */
    display: none;
    position: fixed;
    z-index: 2; /* Stay on top */
    top: 40%; /* Stay at the top */
    right: 0;
    margin-left: 15px;
    background-color: #ffffff;
    bottom: 0 !important;
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden; /* Disable horizontal scroll */
    padding-top: 20px; /* Place content 60px from the top */
    padding-left: 15px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    border-radius: 15px 0px 0px 15px;
    box-shadow: 5px 5px #888888;
}

#root{
    width: 100% ;
    height: 100%;
}

.card-header{
    border-radius: 10px !important;
}

.central{
    margin-top: 20rem;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 10px !important;
}



.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

button{
    border-width: none !important;
    border-style: none !important;
    background: none ;
}
.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}

.img_mini{
    width: 25px;
    height: 25px;
    cursor: pointer;
}


.mg-top{
    margin-top: 5%;
}
.container{
    padding: 0;
}

html, 
body {
    height: 100%;
}

.il{
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
    margin-left: 2rem;
}

.ir{
    float: right;
    margin-top: 10px !important;
    font-size: 20px;
}

.Img{
    width: 60px;
    height: 60px;
    margin-bottom: 15%;
    object-fit: cover;
}

.mid_Content{
    height: 100%!important;
    max-height: 100% !important;
}

.img_bot{
    width: 45px;
    height: 45px;
    margin: 10px 20px 10px 20px;
}

.img_bot_right{
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    right: 0%;
    bottom: 1rem !important;
    
}

.cars_cont{
    text-align: center;
    align-content: center;
}

.card_title{
    font-size: 25px;
}

.card{
    padding: 2 !important;
    -webkit-filter: drop-shadow(15px 10px 5px #888888);
            filter: drop-shadow(15px 10px 5px #888888);
}

.ct{
    max-height: 100% !important;
    height: 100%;
}

.nave{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
}

.footer {
    position: fixed !important;
    left: 0;
    bottom: 1% !important;
    width: 100%;
  }
  
.filter{
    opacity: 0.4;
}

.no_filter{
    -webkit-filter: grayscale(0%) !important;
            filter: grayscale(0%) !important;
}

.disabledCursor{
    cursor:default;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 30px !important;
}

.title{
    margin-left: 2rem;
    margin-top: -1rem;
    margin-bottom: -1.4rem;
}

.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}


.mg-top{
    margin-top: 5%;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.card-header{
    border-radius: 10px !important;
}

.central{
    margin-top: 20rem;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 10px !important;
}

.title{
    margin-left: 2rem;
    margin-top: -1rem;
    margin-bottom: -1.4rem;
}

.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

button{
    border-width: none !important;
    border-style: none !important;
    background: none ;
}
.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}

.img_mini{
    width: 25px;
    height: 25px;
    cursor: pointer;
}


.mg-top{
    margin-top: 5%;
}
.il{
    display: inline;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.html,body{
    height: 100%;
    max-width: 100%;
    max-height: 100%!important;
    width: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}
.il{
    display: inline;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.html,body{
    height: 100%;
    max-width: 100%;
    max-height: 100%!important;
    width: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}
.il{
    display: inline;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.html,body{
    height: 100%;
    max-width: 100%;
    max-height: 100%!important;
    width: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}
.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.card-header{
    border-radius: 10px !important;
}

.central{
    margin-top: 20rem;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 10px !important;
}



.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

button{
    border-width: none !important;
    border-style: none !important;
    background: none ;
}
.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}

.img_mini{
    width: 25px;
    height: 25px;
    cursor: pointer;
}


.mg-top{
    margin-top: 5%;
}
.img_bot{
    width: 45px;
    height: 45px;
    margin: 10px 20px 10px 20px;
}
