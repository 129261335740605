.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.checks{
    width: 30px;
    height: 30px;
    cursor: pointer;
}



.confirmarModal{
    border-right: 5px solid;
    border-color: #fff;
    height: 130px; /* 100% Full-height */
    width: 0px; /* 0 width - change this with JavaScript */
    display: none;
    position: fixed;
    z-index: 2; /* Stay on top */
    top: 40%; /* Stay at the top */
    right: 0;
    margin-left: 15px;
    background-color: #ffffff;
    bottom: 0 !important;
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden; /* Disable horizontal scroll */
    padding-top: 20px; /* Place content 60px from the top */
    padding-left: 15px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    border-radius: 15px 0px 0px 15px;
    box-shadow: 5px 5px #888888;
}

#root{
    width: 100% ;
    height: 100%;
}

.card-header{
    border-radius: 10px !important;
}

.central{
    margin-top: 20rem;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 10px !important;
}



.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

button{
    border-width: none !important;
    border-style: none !important;
    background: none ;
}
.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}

.img_mini{
    width: 25px;
    height: 25px;
    cursor: pointer;
}


.mg-top{
    margin-top: 5%;
}