.il{
    display: inline ;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

#root{
    width: 100% ;
    height: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}

.card_items{
    border-radius: 30px !important;
}

.title{
    margin-left: 2rem;
    margin-top: -1rem;
    margin-bottom: -1.4rem;
}

.form_text{
    font-size: 30px;
    margin: 0px 4px 0px 4px;
}

.item_tipo{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.item_tipo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.img{
    width: 45px;
    height: 45px;
}


.mg-top{
    margin-top: 5%;
}