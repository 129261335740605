body{
  background-color: #F9F9F9;
}

.marginTop{
  margin-top: 6rem;

}
.jBorder{
display: flex;
margin-top: -200px !important;
padding: 0 0 0 0 !important;
margin: 0 0 0 0 !important;
filter: drop-shadow(15px 10px 5px #888888);

}


.imageBack{
  background-image: url("../../images/undrawBack.svg") !important;
  background-repeat: no-repeat;
  background-attachment: initial;
  background-position: center;
  background-size: cover;
  background-size: 100% 100%;
  font-weight: 900 !important;
  font-size: large;
}

.imgBack{
  filter: contrast(85%) !important;
}

strong{
  color: #F6AE2D;
}

