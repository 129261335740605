.container{
    padding: 0;
}

html, 
body {
    height: 100%;
}

.il{
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
    margin-left: 0px !important;
    display: inline !important;
}

.title{
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.ir{
    float: right;
    margin-top: 10px !important;
    font-size: 20px;
}

.Img{
    width: 60px;
    height: 60px;
    margin-bottom: 15%;
    object-fit: cover;
}

.mid_Content{
    height: 100%!important;
    max-height: 100% !important;
}

.ImgSide{
    width: 40px;
    height: 40px;
    left: 97.5%;
    top: 50%;
    z-index: 1;
    filter: drop-shadow(15px 10px 5px #888888);
    border-style: solid;
    padding: 3px;
    border-radius: 10px;
    border-color: #f1f1f1;
    background-color: #f1f1f1;;
    position: absolute;
}

.img_bot{
    width: 45px;
    height: 45px;
    margin: 10px 20px 10px 20px;
}

.img_bot_right{
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    right: 0%;
    bottom: 1rem !important;
    
}

.cars_cont{
    text-align: center;
    align-content: center;
}

.card_title{
    font-size: 25px;
}

.card{
    padding: 2 !important;
    filter: drop-shadow(15px 10px 5px #888888);
}

.ct{
    max-height: 100% !important;
    height: 100%;
}

.nave{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
}

.footer {
    position: fixed !important;
    margin-right: 14rem!important;
    margin-left: 4.5rem!important;
    width: 100%!important;
    bottom: 1% !important;

  }
  
.filter{
    opacity: 0.4;
}

.no_filter{
    filter: grayscale(0%) !important;
}

.disabledCursor{
    cursor:default;
}

p{
    font-weight: 900 !important;
}

.logo{
    width: 120px;
    height: 120px;
}
/* The side navigation menu */
.sidenav {
    border-right: 5px solid;
    border-color: #fff;
    height: 100%; /* 100% Full-height */
    width: 14rem; /* 0 width - change this with JavaScript */
    display: inline-block;
    position: fixed;
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #F6AE2D; 
    bottom: 0 !important;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }

  .sidenav h5{
      font-size: 24px;
  }
  
.sidenav img{
    border: 2px solid;
    border-color: #f0f0f5;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 50px;
}


  /* The navigation menu links */
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 14px;
    font-weight:  500;
    color: #000;
    display: block;
    transition: 0.3s;
  }

  .collapse a{
    margin-left: 20px;
  }


  
  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  /* Position and style the close button (top right corner) */
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  /* Style page content - use this if you want to push the page content to the right when you open the side navigation */
  #main {
    transition: margin-left .5s;
    padding: 20px;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }




@media (max-width: 579px){
    .sidenav {
        height: 0px; /* 100% Full-height */
        width: 0px; /* 0 width - change this with JavaScript */
        display: inline-block;
        position: fixed;
        z-index: 1; /* Stay on top */
        top: 0; /* Stay at the top */
        left: 0;
        background-color: #F6AE2D; 
        bottom: 0 !important;
        overflow-x: hidden; /* Disable horizontal scroll */
        padding-top: 60px; /* Place content 60px from the top */
        transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
      }

    .img_bot{
        width: 35px !important;
        height: 35px !important;
        margin: 5px 10px 5px 10px !important;
    }

    .nave{
        
        justify-content: center!important;
        margin-left: 35px !important;
        margin-right: 0px !important;
        margin-top: 30px !important;
        

    }

    }