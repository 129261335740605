.container{
    padding: 0;
}

html, 
body {
    height: 100%;
}

.il{
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
    margin-left: 2rem;
}

.ir{
    float: right;
    margin-top: 10px !important;
    font-size: 20px;
}

.Img{
    width: 60px;
    height: 60px;
    margin-bottom: 15%;
    object-fit: cover;
}

.mid_Content{
    height: 100%!important;
    max-height: 100% !important;
}

.img_bot{
    width: 45px;
    height: 45px;
    margin: 10px 20px 10px 20px;
}

.img_bot_right{
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    right: 0%;
    bottom: 1rem !important;
    
}

.cars_cont{
    text-align: center;
    align-content: center;
}

.card_title{
    font-size: 25px;
}

.card{
    padding: 2 !important;
    filter: drop-shadow(15px 10px 5px #888888);
}

.ct{
    max-height: 100% !important;
    height: 100%;
}

.nave{
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
}

.footer {
    position: fixed !important;
    left: 0;
    bottom: 1% !important;
    width: 100%;
  }
  
.filter{
    opacity: 0.4;
}

.no_filter{
    filter: grayscale(0%) !important;
}

.disabledCursor{
    cursor:default;
}