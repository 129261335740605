.il{
    display: inline;
    color: #F6AE2D;
    font-size: 70px;
    font-weight: bold;
}

.html,body{
    height: 100%;
    max-width: 100%;
    max-height: 100%!important;
    width: 100%;
}

.ir{
    display: inline;
    float: right;
    margin-top: 10px;
    font-size: 20px;
}