.esteiraAtivo{
    border-style: solid;
    border-width: 2px;
    box-shadow: 5px 5px #888888;
    background: #F5F5F5;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
    border-radius: 10px !important;
}

.esteiraAtivo p{
    margin-top: 1rem;
    margin-left: 3rem;
}

.esteiraDesativo{
    border-style: solid;
    border-width: 2px;
    box-shadow: 5px 5px #888888;
    background: #797877;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
    border-radius: 10px !important;
}
.esteiraDesativo p{
    margin-top: 1rem;
    margin-left: 3rem;
}


.itemAtivo{
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    box-shadow: 5px 5px #888888;
    background-color: #F6AE2D;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.itemDesativo{
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    box-shadow: 5px 5px #888888;
    background: #BAB8B5!important;
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
}

.itemAtivo .card-header {
    background-color: #F6AE2D!important;
}

.itemDesativo .card-header{
    background-color: #BAB8B5!important;
}